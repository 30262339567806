import { mutateGraphQL } from '@/data';
import { MutationCommerceWriteArgs, Order, Payment, Staff } from '@/types/schema';
import { gql } from '@apollo/client';
import axios from 'axios';

export const getPaymentAndAdditionalCharge = ( payment: Payment, order?: Order ) => {
	const totalWithTip = payment.amount + ( payment.tip || 0 );
	const additionalCharge = payment?.prices?.[ 0 ];
	const chargeAmount = additionalCharge
		? additionalCharge.isPercent
			? totalWithTip * ( additionalCharge.value / 100 )
			: additionalCharge.value / 100
		: 0;
	const paymentAmount = totalWithTip + chargeAmount;
	
	const refundedChildPayments = order?.payments.filter( ( childPayment ) =>
		childPayment.parentPaymentId === payment.id || childPayment.parentPaymentId === payment.externalId );
	const refundedAmount = refundedChildPayments?.reduce( ( total,
		childPayment ) => total + ( childPayment.refundedAmount || 0 ), 0 ) || 0;
	
	const paymentAmountAfterRefunds = paymentAmount - refundedAmount;
	const totalWithTipAfterRefunds = totalWithTip - refundedAmount;
	
	return {
		totalWithTip: totalWithTipAfterRefunds,
		paymentAmount,
		chargeAmount,
		additionalCharge,
		paymentAmountAfterRefunds,
		refundedAmount,
	};
};

export const setPaidTotalAfterPayment = async ( order: Order,
	paidTotal: number,
	payment: Payment,
	staff: Staff,
	firstName: string ) => {
	await mutateGraphQL<MutationCommerceWriteArgs>( {
		mutation : gql`mutation CommerceWrite_bfad($id: String, $externalId: String, $method: String,$customNumber: Boolean, $input: OrderValidator, $remove: Boolean,  $customCreatedAt: DateTime) {
			commerceWrite(id: $id, externalId: $externalId, method: $method, customNumber: $customNumber, input: $input, remove: $remove, customCreatedAt: $customCreatedAt) {
				id
			}}`,
		variables: {
			id   : order.id,
			input: {
				paid     : false,
				paidTotal: order.paidTotal <= 0 || paidTotal <= 0 ? 0 : paidTotal,
				metadata : {
					paymentByInfo: staff.id
						? order.metadata?.paymentByInfo ? {
							...order.metadata.paymentByInfo,
							[ payment?.externalId || payment?.id ]: {
								id  : staff.id,
								name: firstName,
							},
						} : {
							[ payment?.externalId || payment?.id ]: {
								id  : staff.id,
								name: firstName,
							},
						} : undefined,
				},
			},
			
		},
	} );
	try {
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/importOrder`, { id: order.id } );
	} catch {
	}
};
